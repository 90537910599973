import type {
  ActionTree,
  GetterTree,
  MutationTree
} from 'vuex';
import { saveCityOfSale } from '@@/shared/composables/cityOfSale';
import {
  FLAGS,
  useFeatureFlagsStore
} from '../featureFlags';
import type {
  CitySelectType
} from '@/types/Cities';

import { useMeetingsStore } from '@/store/meetings';
import { useCitiesStore } from '@/store/cities';

interface CalendarFilters {
  /* FIXME фильтры должны быть в сторе, сделать в задаче по фильтрам */
  type: 'event' | 'meeting'; // тип события календаря (встреча/мероприятие)
  formatType: 'online' | 'offline'; // формат события календаря (онлайн/ офлайн)
  dateStart: Date; // дата начала сортировки мероприятий и встреч календаря
  dateEnd: Date; // дата конца сортировки мероприятий и встреч календаря

  showOnlyRecordedEvents: boolean; // для получения списка мероприятий календаря, на которых записан текущий агент
  showOnlyNotRecordedMeetings: boolean; // для получения списка мероприятий календаря, на которых не записан текущий агент
  eventCity: Array<string>; // фильтр мероприятий календаря по городам (несколько значений)

  meetingStatus: Array<string>; // фильтрация встреч календаря по статусам (несколько значений)
  meetingClient: string; // фильтрация встреч календаря по клиенту (поиск по почте, номеру, имени, фамилии или отчеству)
  bookingId: number; // фильтр встреч по сделке
}

interface SpecsItem {
  value?: string;
  label: string;
}

interface Specs {
  type: Array<SpecsItem>;
  formatType: Array<SpecsItem>;
  meetingStatus: Array<SpecsItem>;
  eventCity: Array<SpecsItem>;
  showOnlyRecordedEvents: Array<SpecsItem>;
}

interface State {
  filters?: CalendarFilters;
}

export const state = (): State => ({
  filters: undefined
});

/* eslint-disable-next-line */
export type RootState = any;

export const getters: GetterTree<State, RootState> = {
  specs (_, __, rootState) {
    const specs: Specs = {
      type: [ // Тип календаря
        {
          value: 'all',
          label: 'Все'
        },
        {
          value: 'meeting',
          label: 'Встречи'
        },
        {
          value: 'event',
          label: 'Мероприятия'
        }
      ],

      formatType: [ // Тип события
        {
          value: 'all',
          label: 'Любой'
        },
        {
          value: 'online',
          label: 'Онлайн'
        },
        {
          value: 'offline',
          label: 'Оффлайн'
        }
      ],
      meetingStatus: [ // Статус встречи
      ],

      eventCity: [ // Город мероприятия
      ],
      showOnlyRecordedEvents: [ // Участник мероприятия
        {
          value: 'all',
          label: 'Не важно'
        },
        {
          value: 'true',
          label: 'Участвую'
        },
        {
          value: 'false',
          label: 'Не участвую'
        }
      ]
    };
    const { meetings } = useMeetingsStore();
    const citiesStore = useCitiesStore();
    const meetingStatuses = meetings?.statuses;
    const cities = citiesStore.cities?.map(({ text, citySlug }: CitySelectType) => ({
      label: text,
      value: citySlug
    }));

    specs.meetingStatus = [
      ...specs.meetingStatus,
      ...meetingStatuses
    ];

    specs.eventCity = [
      ...specs.eventCity,
      ...cities
    ];

    return specs;
  }
};
export const actions: ActionTree<State, RootState> = {
  async setFilters ({ commit, dispatch }, payload): Promise<void> {
    commit('SET_FILTERS', payload);
    await dispatch('calendar/getEvents', undefined, { root: true });
  },

  async addFilters ({
    commit,
    state,
    dispatch
  }, payload): Promise<void> {
    const filters: CalendarFilters = {
      ...state.filters,
      ...payload
    };

    const featureFlagsStore = useFeatureFlagsStore();
    const cityOfSaleFeatureEnabled = featureFlagsStore.isEnabledFlag(FLAGS.cityOfSale);

    if (cityOfSaleFeatureEnabled && filters.eventCity?.length === 1) {
      const citySlug = filters.eventCity[0];
      const citiesStore = useCitiesStore();
      const found = citiesStore.rawCities.find((city) => city.slug === citySlug);

      if (found) {
        saveCityOfSale(found);
      }
    }
    commit('SET_FILTERS', filters);
  },

  async resetFilters ({ commit, dispatch }): Promise<void> {
    commit('SET_FILTERS', undefined);
    await dispatch('calendar/getEvents', undefined, { root: true });
  }
};

export const mutations: MutationTree<State> = {
  SET_FILTERS (
    state: State,
    payload
  ): void {
    state.filters = payload;
  }
};
