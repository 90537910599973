import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useVuexStore } from '@/plugins/app-context';
import type { User } from '@/types/User';

export const useAuthStore = defineStore('authStore', () => {
  // преобразователь, чтобы мы могли переиспользовать и зависеть от vuex-стора $auth только в одном месте
  const vuex = useVuexStore();
  const user = computed<User | undefined>(() => vuex.$auth?.user);
  const role = computed(() => vuex.getters.role as string | undefined);
  const strategy = computed(() => {
    return vuex.$auth?.$state?.strategy as string | undefined;
  });

  function setUser (value: User) {
    vuex.$auth.setUser(value);
  }

  return {
    user,
    role,
    strategy,
    setUser
  };
});
