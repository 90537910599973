import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { mapActions, mapGetters, mapState } from 'vuex';
import { PortalTarget } from 'portal-vue';
import { provide } from 'vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheModalIframe from '@/components/layout/TheModalIframe.vue';
import TheContextMenu from '@/components/layout/TheContextMenu.vue';
import TheModal from '@/components/layout/TheModal.vue';
import { usePortalApi } from '@/plugins/api/portal';
import { useScreenPlugin } from '@/plugins/vue-screen';
export default {
  name: 'Default',
  components: {
    TheModal: TheModal,
    TheContextMenu: TheContextMenu,
    TheModalIframe: TheModalIframe,
    TheFooter: TheFooter,
    TheHeader: TheHeader,
    PortalTarget: PortalTarget,
    TheBurgerMenu: function TheBurgerMenu() {
      return import('@/components/layout/TheBurgerMenu');
    }
  },
  setup: function setup() {
    provide('$portalApi', usePortalApi());
    provide('$screen', useScreenPlugin());
  },
  data: function data() {
    return {
      height: null
    };
  },
  head: function head() {
    return {
      script: [
      // GA start
      {
        innerHTML: "\n            (function(i,s,o,g,r,a,m){i[\"GoogleAnalyticsObject\"]=r;i[r]=i[r]||function(){\n            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n            })(window,document,\"script\",\"https://www.google-analytics.com/analytics.js\",\"ga\");\n            window.ga(\"create\", \"UA-111285949-5\", \"auto\");\n            setTimeout(function(){ window.ga(\"send\", \"pageview\"); }, 1000)\n          "
      },
      // GTM start
      {
        innerHTML: "\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','".concat(this.googleTagManagerId, "');\n          ")
      }
      // GTM end
      ],
      noscript: [
      // GTM noscripts
      {
        innerHTML: "\n            <iframe src=\"https://www.googletagmanager.com/ns.html?id='".concat(this.googleTagManagerId, "\"\n                    height=\"0\"\n                    width=\"0\"\n                    style=\"display:none;visibility:hidden\"\n            ></iframe>\n          "),
        body: true
      }],
      __dangerouslyDisableSanitizers: ['script', 'noscript']
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('notification', ['notifications'])), mapGetters({
    isOpened: 'header/getHeaderState',
    currentCity: 'getActiveCity'
  })), {}, {
    googleTagManagerId: function googleTagManagerId() {
      var _this$currentCity;
      return ((_this$currentCity = this.currentCity) === null || _this$currentCity === void 0 ? void 0 : _this$currentCity.name) !== 'Москва' ? 'GTM-MR9TQWJ' : 'GTM-PB4MD35';
    },
    isFooter: function isFooter() {
      var _this$$route;
      return !((_this$$route = this.$route) !== null && _this$$route !== void 0 && (_this$$route = _this$$route.meta) !== null && _this$$route !== void 0 && _this$$route.hideFooter);
    },
    isFixed: function isFixed() {
      var _this$$route2, _this$$route3;
      return ((_this$$route2 = this.$route) === null || _this$$route2 === void 0 || (_this$$route2 = _this$$route2.name) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.includes('contract')) || ((_this$$route3 = this.$route) === null || _this$$route3 === void 0 || (_this$$route3 = _this$$route3.name) === null || _this$$route3 === void 0 ? void 0 : _this$$route3.includes('account'));
    }
  }),
  watch: {
    'notifications.length': function notificationsLength() {
      this.getHeaderHeight();
    }
  },
  mounted: function mounted() {
    this.getHeaderHeight();
  },
  methods: _objectSpread(_objectSpread({}, mapActions({
    toggleHeader: 'header/toggleHeader'
  })), {}, {
    getHeaderHeight: function getHeaderHeight() {
      var _this = this;
      this.$nextTick(function () {
        _this.height = "".concat(document.querySelector('header').getBoundingClientRect().height, "px");
      });
    }
  })
};