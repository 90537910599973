import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.showComponent ? _c('div', {
    class: [_setup.$style.ModalSelectSaleCity, _defineProperty({}, _setup.$style.hiding, _setup.hidingAnimation)],
    on: {
      "animationend": _setup.onAnimationEnd
    }
  }, [_c('SimpleOverlay', {
    class: _setup.$style.overlay
  }), _vm._v(" "), _c('section', {
    class: [_setup.$style.modalContent, _defineProperty({}, _setup.$style.loading, _setup.loading)]
  }, [_setup.loading ? _c(_setup.SLoader, {
    attrs: {
      "size": "10",
      "unit": "rem"
    }
  }) : [_c(_setup.NTitle, {
    class: _setup.$style.title,
    attrs: {
      "semantic-head-level": "2",
      "style-head-level": "3"
    }
  }, [_vm._v("\n        Выберите ваш город продажи\n      ")]), _vm._v(" "), _c('p', {
    class: _setup.$style.description
  }, [_vm._v("\n        Для продолжения работы необходимо указать основной город, в котором вы работаете с нашими проектами. Изменить данные можно в профиле\n      ")]), _vm._v(" "), _c(_setup.SSelect, {
    class: _setup.$style.selectCity,
    attrs: {
      "label": "Город продажи",
      "placeholder": "Выберите город"
    },
    model: {
      value: _setup.selectedCityId,
      callback: function callback($$v) {
        _setup.selectedCityId = $$v;
      },
      expression: "selectedCityId"
    }
  }, _vm._l(_setup.citiesStore.rawCities, function (city) {
    return _c(_setup.SOption, {
      key: city.id,
      attrs: {
        "value": city.id,
        "label": city.name
      }
    }, [_vm._v("\n          " + _vm._s(city.name) + "\n        ")]);
  }), 1), _vm._v(" "), _c(_setup.SButton, {
    class: _setup.$style.saveButton,
    attrs: {
      "disabled": !_setup.selectedCityId
    },
    on: {
      "click": _setup.onSaveButtonClick
    }
  }, [_vm._v("\n        Сохранить данные\n      ")])]], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };