import { Context } from '@nuxt/types';
const piniaPlugin = ({ $pinia, app }: Context): void => {
  // eslint-disable-next-line
  $pinia.use(({ store }: { store: any }) => {
    store.$axios = app.$axios;
    store.$router = app.router;
    store.$sentry = app.$sentry;
    store.$api = app.$api;
    store.$portal = app.$portal;
    store.$notify = app.$notify;
    store.$auth = app.$auth;
  });
};

export default piniaPlugin;
