var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.HeaderButton, {
    class: _vm.$style.HelpButton,
    attrs: {
      "icon": "i-question-mark"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };