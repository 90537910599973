import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
export default {
  name: 'IconArtwLogo',
  props: {
    isWhiteW: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wColor: function wColor() {
      if (this.isWhiteW) {
        return '#FCFCFC';
      }
      return '#DC2655';
    }
  }
};