// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_gMT99{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;background-color:#fafafa;justify-content:space-between;padding:1.6rem 8rem 1.6rem 7.6rem}@media only screen and (max-width:1023.98px){.Header_gMT99{padding:2.4rem 4rem}}@media only screen and (max-width:767.98px){.Header_gMT99{padding:1.6rem 1.2rem 1.6rem 2rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center}}.logoLink_\\+6uKx{display:-webkit-box;display:-ms-flexbox;display:flex;padding-bottom:5px;padding-top:5px}.button_INHfX.notificationsButton_hvvSa{padding:1.2rem}.rightSide_CpXTo{display:-webkit-box;display:-ms-flexbox;display:flex;gap:.8rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center}@media only screen and (max-width:1023.98px){.rightSide_CpXTo{gap:1.6rem}}.menuButton_VTI80{max-height:4.4rem}.leftSide_TirCg{-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header_gMT99",
	"logoLink": "logoLink_+6uKx",
	"button": "button_INHfX",
	"notificationsButton": "notificationsButton_hvvSa",
	"rightSide": "rightSide_CpXTo",
	"menuButton": "menuButton_VTI80",
	"leftSide": "leftSide_TirCg"
};
module.exports = ___CSS_LOADER_EXPORT___;
