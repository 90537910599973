import { useAuthStore } from '@@/lk_broker/store/authStore';
import {
  tryGetFromStorage,
  tryRemoveFromStorage,
  trySaveToStorage
} from '@@/shared/lib/utils/storageUtils';
import type { CityResponse } from 'lk_broker/types/Cities';

const cityOfSaleLocalStorageKey = 'filter_cityOfSale_stored-value';

function isCity (arg: unknown): arg is CityResponse {
  return typeof arg === 'object' &&
    arg != null &&
    'globalId' in arg &&
    'slug' in arg;
}

export function getCityOfSale (): CityResponse | undefined {
  const fromLocal = tryGetFromStorage('localStorage', cityOfSaleLocalStorageKey);

  try {
    if (fromLocal.success && fromLocal.value) {
      const parsed = JSON.parse(fromLocal.value);

      if (isCity(parsed)) {
        return parsed;
      }
    }
  } catch {}

  return useAuthStore().user?.city_of_sale;
}

export function saveCityOfSale (city: CityResponse) {
  trySaveToStorage('localStorage', cityOfSaleLocalStorageKey, JSON.stringify(city));
}

export function clearCityOfSale () {
  tryRemoveFromStorage('localStorage', cityOfSaleLocalStorageKey);
}
