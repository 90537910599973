import allCities from '../queries/allCities.graphql';
import allSocials from '../queries/allSocials.graphql';

export const state = () => ({
  active_city: null,
  citiesGlobal: [],
  reglament: {
    data: {
      file: {},
      text: ''
    },

    isFetched: false
  },
  oldProjectsList: [], // FixMe: Исправить в рамках strana_lk-1069
  social: []
});
/* eslint-disable no-extra-parens */
export const getters = {
  getReglamentLink: (state) => state.reglament?.data?.file?.aws ? state.reglament.data.file.aws : '',
  getReglamentText: (state) => state.reglament?.data?.text ? state.reglament.data.text : '',
  isCommercialPageHidden: (state) => state.active_city?.hasCommercialPage &&
    state.active_city?.isCommercialPageHidden,
  getActiveCity: (state) => state.active_city,
  getCityById: (state) => (id) => state.citiesGlobal.find((city) => city.id === id),

  role: (state) => {
    const { strategy } = state.auth;

    if (strategy === 'represes') {
      return 'repres';
    }

    if (strategy === 'agents') {
      return 'agent';
    }
  }
};

export const actions = {
  async nuxtServerInit ({ commit, dispatch }) {
    try {
      const [citiesRes, socialRes] = await Promise.all([
        dispatch('getAllCities'),
        dispatch('getAllSocials')
      ]);

      if (citiesRes.data?.currentCity) {
        commit('SET_ACTIVE_CITY', citiesRes.data.currentCity);
      }

      const cities = citiesRes?.data?.allCities?.edges?.length
        ? citiesRes.data.allCities.edges.map((item) => item.node)
        : [];
      const social = socialRes?.data?.allSocials
        ? socialRes.data.allSocials.sort((a, b) => a.order - b.order)
        : [];

      commit('SET_CITIES', cities);
      commit('SET_SOCIAL', social);
    } catch (error) {
      console.error('nuxtServerInit/error', error);
      this.$sentry.captureException(error);
    }
  },

  async getAllCities () {
    try {
      return await this.$portal.$post('/graphql/', {
        query: allCities.loc.source.body
      });
    } catch (error) {
      console.error('🚀 ~ file: index.js ~~ getAllCities ~ error', error);

      if (this.$sentry) {
        this.$sentry.captureException(error);
      }
    }
  },

  async getAllSocials () {
    try {
      return await this.$portal.$post('/graphql/', {
        query: allSocials.loc.source.body
      });
    } catch (error) {
      console.error('🚀 ~ file: index.js ~ getAllCities ~ error', error);

      if (this.$sentry) {
        this.$sentry.captureException(error);
      }
    }
  },

  async getReglament ({ state, commit }) {
    if (!state.reglament.isFetched) {
      try {
        const response = await this.$axios.$get(this.$api.documents.detail('reglament'));
        await commit('SET_REGLAMENT', response);

        return response;
      } catch (error) {
        console.log('🚀 ~ file: index.js (store) ~ line 31 ~ getReglament ~ error', error);
        this.$sentry.captureException(error);
        throw new Error(error);
      }
    }

    return state.reglament.data;
  }
};

export const mutations = {
  SET_ACTIVE_CITY (state, payload) {
    state.active_city = payload;
  },
  SET_REGLAMENT (state, payload) {
    state.reglament.data = payload;
    state.reglament.isFetched = true;
  },
  SET_SOCIAL (state, payload) {
    state.social = [...payload];
  },
  SET_CITIES (state, payload) {
    state.citiesGlobal = payload;
  }
};
