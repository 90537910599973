import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.$style.HeaderWithLogin, _defineProperty({}, _vm.$style['_menu'], _vm.menu)]
  }, [!_vm.menu && _vm.menuItems.length ? _c('nav', {
    class: _vm.$style.linkWrapper
  }, [_c('HeaderNavigation', {
    attrs: {
      "items": _vm.menuItems
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.$style.rightMenuWrapper
  }, [_vm.qrCodeData && !_vm.menu ? _c('HeaderQRCode', {
    attrs: {
      "qr-code-data": _vm.qrCodeData
    }
  }) : _vm._e(), _vm._v(" "), _c('NuxtLink', {
    class: _vm.$style.faqLink,
    attrs: {
      "to": "/faq"
    }
  }, [_c('SIcon', {
    class: _vm.$style.faqIcon,
    attrs: {
      "icon": "i-question",
      "size": "20"
    }
  })], 1), _vm._v(" "), _c('HeaderUserBtn', {
    attrs: {
      "wrapper-id": _vm.userWrapperId,
      "btn-id": _vm.userBtnId
    },
    on: {
      "logout": function logout($event) {
        return _vm.$emit('logout');
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };