import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import type {
  CityResponse,
  CitySelectType
} from '@/types/Cities';

export const useCitiesStore = defineStore('cities', () => {
  const rawCities = ref<Array<CityResponse>>([]);
  const cities = computed<Array<CitySelectType>>(
    () => rawCities.value.map(
      (rawCity) => ({
        ...rawCity,
        text: rawCity.name,
        value: rawCity.id,
        citySlug: rawCity.slug
      })
    )
  );

  const { $axios } = useContext();

  function getCityById (cityId: number | null | undefined): CitySelectType | undefined {
    return cities.value.find(({ id }) => id === cityId);
  }

  async function getCities (forceReload = false): Promise<void> {
    if (!rawCities.value.length || forceReload) {
      rawCities.value = await $axios.get<Array<CityResponse>>('api/cities').then((response) => response.data);
    }
  }

  return {
    getCityById,
    cities,
    rawCities,
    ...decorateApiActions({
      getCities
    }, 'store/cities.ts')
  };
});
