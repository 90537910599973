var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "rootRef",
    class: _vm.$style.HeaderHelpButton
  }, [_c(_setup.HelpButton, {
    nativeOn: {
      "click": function click($event) {
        return _setup.helpButtonClick.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _setup.helpContentOpen ? _c(_setup.HelpContent, {
    ref: "helpContent",
    class: _vm.$style.helpContent
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };