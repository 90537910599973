import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { SButton, SIcon } from '@strana-artw/ui-kit';
import { scrollTo } from '@@/common/utils/scroll';
import IconArtw from '../icons/iconArtwLogo';
export default {
  name: 'TheFooter',
  components: {
    IconArtw: IconArtw,
    SIcon: SIcon,
    SButton: SButton
  },
  data: function data() {
    return {
      logo: '/images/logoGray.svg'
    };
  },
  computed: {
    currentYear: function currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    scrollToTop: function scrollToTop() {
      scrollTo();
    }
  }
};