import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapActions, mapGetters } from 'vuex';
import { SButton, SIcon, SLink } from '@strana-artw/ui-kit';
import { storeToRefs } from 'pinia';
import { BURGER_MENU_ID } from '@@/shared/constants/app-ids';
import { useMenuStore } from '@/store/menu';
export default {
  name: 'TheBurgerMenu',
  components: {
    SIcon: SIcon,
    SButton: SButton,
    SLink: SLink
  },
  setup: function setup() {
    var menuStore = useMenuStore();
    var _storeToRefs = storeToRefs(menuStore),
      burgerMenu = _storeToRefs.burgerMenu;
    return {
      BURGER_MENU_ID: BURGER_MENU_ID,
      burgerMenu: burgerMenu
    };
  },
  data: function data() {
    return {
      hoveredLink: '',
      isOpened: false,
      links: [{
        name: 'Проектные документы',
        href: 'https://strana.com/documents/'
      }, {
        name: 'Контакты',
        href: 'https://strana.com/contacts/'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    burgerMenuItems: 'header/getBurgerMenuItems',
    show: 'header/getHeaderState'
  })), {}, {
    menuItems: function menuItems() {
      return this.burgerMenu;
    },
    classes: function classes() {
      return _defineProperty(_defineProperty({}, this.$style._opened, this.show), this.$style['has-banner'], this.hasBanner);
    },
    isMobile: function isMobile() {
      var _window;
      if (!process.client) {
        return false;
      }
      return (_window = window) === null || _window === void 0 || (_window = _window.$nuxt) === null || _window === void 0 ? void 0 : _window.$breakpoint.smAndDown;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions({
    toggleHeader: 'header/toggleHeader'
  })), {}, {
    handleMouseEnter: function handleMouseEnter(val) {
      if (this.isMobile) {
        return;
      }
      this.hoveredLink = val;
    },
    handleMouseLeave: function handleMouseLeave() {
      this.hoveredLink = '';
    },
    handleClose: function handleClose() {
      this.toggleHeader();
    }
  })
};