import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// Mixins
import Themeable from '../../../mixins/themeable';

// Utilities
import mixins from '../../../utils/mixins';
export default mixins(Themeable).extend({
  name: 'VApp',
  props: {
    dark: {
      type: Boolean,
      default: undefined
    },
    id: {
      type: String,
      default: 'app'
    },
    light: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    isDark: function isDark() {
      //  this?.$nuxt?.$theme?.dark ||
      return false;
    },
    minHeight: function minHeight() {
      return typeof window === 'undefined' ? '100vh' : "".concat(this.$breakpoint.height, "px"); // this.$breakpoint.height;
    }
  },
  render: function render(h) {
    return h('div', {
      staticClass: 'v-application',
      class: _objectSpread({}, this.themeClasses),
      attrs: {
        'data-app': true
      },
      // style: {
      //   'min-height': `calc(${this.minHeight} - var(--header-h))`
      // },
      domProps: {
        id: this.id
      }
    }, this.$slots.default);
  }
});