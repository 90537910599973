import accountApi from '@/config/api/account';
import bookingApi from '@/config/api/booking';
import documentsApi from '@/config/api/documents';
import pagesApi from '@/config/api/pages';
import projectsApi from '@/config/api/projects';
import propertiesApi from '@/config/api/properties';
import usersApi from '@/config/api/users';
import quizApi from '@/config/api/quiz';
import additionalApi from '@/config/api/additionals';
import fireApi from '@/config/api/fire';
import templatesApi from '@/config/api/templates';
import eventsApi from '@/config/api/events';
import buildingsApi from '@/config/api/buildings';
import newsApi from '@/config/api/news';

const config = {
  ...accountApi,
  ...bookingApi,
  ...documentsApi,
  ...pagesApi,
  ...projectsApi,
  ...propertiesApi,
  ...usersApi,
  ...quizApi,
  ...additionalApi,
  ...fireApi,
  ...templatesApi,
  ...eventsApi,
  ...buildingsApi,
  ...newsApi
};

export default config;
