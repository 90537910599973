// Стор для feature flags
// import { useFeatureFlagsStore, FLAGS } from '@/store/featureFlags';
// При переходе между страницами с бэка запрашиваем список флагов
// В компоненте используем геттер
// setup () {
//   const featureFlagsStore = useFeatureFlagsStore();
//   const { isEnabledFlag } = storeToRefs(featureFlagsStore);
//   return {
//     isEnabledFlag
//   };
// },
// Использовать
// v-if = isEnabledFlag(FLAGS[slug])

import { decorateApiActions } from '@@/shared/utilites/logging';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAxios } from '@/plugins/app-context';

type Flags = Record<string, boolean>;

/* список флагов которые используются в проекте */
export const FLAGS = {
  manager: 'strana_lk-4219', // Связаться с менеджером
  meetingProject: 'strana_lk-4450', // поле проект активное
  bookingButtons: 'strana_lk-4982_f', // кнопки в шахматке - забронировать платно или бесплатно
  redesign: 'strana_lk-4441', // Редизайн
  support: 'strana_lk-4438_f', // Редизайн
  redesignDeals: 'strana_lk-4441_deals', // Редизайн страницы сделок
  cityOfSale: 'strana_lk-4429_f' // Город продаж
} as const;

// TODO: т.к. тут есть флаги из др задач, поправить отдельной задачей.
// перенести флаги в объект FLAGS
const flags: Array<string> = [
  FLAGS.manager,
  FLAGS.meetingProject,
  FLAGS.bookingButtons,
  FLAGS.redesign,
  FLAGS.support,
  FLAGS.redesignDeals,
  FLAGS.bookingButtons
];

export const useFeatureFlagsStore = defineStore('featureFlags', () => {
  const featureFlags = ref<Flags>({});

  async function getFlags (): Promise<void> {
    const url: string = 'api/feature-flags';

    if (!flags.length) {
      return;
    }

    const {
      data
    } = await useAxios().get(url, {
      params: {
        feature_flag: flags
      }
    });

    featureFlags.value = {
      ...data
    };
  }

  function isEnabledFlag (flag: string): boolean {
    return Boolean(featureFlags.value[flag]);
  }

  return {
    featureFlags,
    ...decorateApiActions({ getFlags }, 'featureFlags.ts'),
    isEnabledFlag
  };
});
