import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'HeaderMenuItem',
  components: {
    SIcon: SIcon
  },
  data: function data() {
    return {
      isOpen: false,
      contentHeight: null,
      maxHeight: 0
    };
  },
  computed: {
    style: function style() {
      return this.isMobile ? {
        maxHeight: "".concat(this.maxHeight, "px")
      } : '';
    },
    isMobile: function isMobile() {
      var _window;
      if (!process.client) {
        return false;
      }
      return (_window = window) === null || _window === void 0 || (_window = _window.$nuxt) === null || _window === void 0 ? void 0 : _window.$breakpoint.mdAndDown;
    }
  },
  mounted: function mounted() {
    this.contentHeight = this.$refs.container.scrollHeight;
  },
  methods: {
    handleToggle: function handleToggle() {
      if (!this.isMobile) {
        return;
      }
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.maxHeight = this.contentHeight;
      } else {
        this.maxHeight = 0;
      }
      this.$emit('toggle', this.isOpen);
    }
  }
};